<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/series/banner.jpg" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterpriseService' }"
          >基础服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>咨询服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <p class="introduce">
          当代财务管理者是公司重要的战略决策制定者和执行者之一，
          是穿插于经营管理和资本市场之间的不可或缺的角色。
          但据IBM调查显示，只有23%的财务管理者属于 “价值整合者”，
          即掌握了高超的业务洞察力和高效的财务效率。大量的财务管理者还有诸多欠缺:
        </p>
        <div class="introduce_list">
          <div class="introduce_item">
            <div class="introduce_img">
              <img src="../../assets/img/series/js1.png" alt="" />
            </div>
            <div class="introduce_title">初级财务管理</div>
            <div class="introduce_content">
              “应付帐款”“应收帐款”等往来款项核算问题不清晰，不了解;不会运用EXCEL进行财务报表分析，看不懂三大表,无法将成本法核算的子公司投资转换为权益法核算后进行合并;
            </div>
            <div class="introduce_btn" @click="handleApply">预约咨询</div>
          </div>

          <div class="introduce_item">
            <div class="introduce_img">
              <img src="../../assets/img/series/js2.png" alt="" />
            </div>
            <div class="introduce_title">中级财务管理</div>
            <div class="introduce_content">
              集国财务预算老板不满意，不能支撑企业经营，保证战略落地实施;财务组织日渐庞大，运行成本高，如何组织搭建财务共享中心，降低成本;业务部门不理解财务、不支持财务，冲突不断，束手无策;
            </div>
            <div class="introduce_btn" @click="handleApply">预约咨询</div>
          </div>

          <div class="introduce_item">
            <div class="introduce_img">
              <img src="../../assets/img/series/js3.png" alt="" />
            </div>
            <div class="introduce_title">高级财务管理</div>
            <div class="introduce_content">
              不能清晰的辨明企业自身优劣势，并购方案可操作行差，成功率低过分依赖银行信贷，缺乏开拓融资产品或非银行融资渠道的能力;企业投资战略制定不合理，影响企业经营发展;
            </div>
            <div class="introduce_btn" @click="handleApply">预约咨询</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">上市公司系列</div>
        <div class="series_box">
          <div class="left_img">
            <img src="../../assets/img/series/series.png" alt="" />
          </div>
          <div class="right_list">
            <div class="right_item">
              <div class="cir"></div>
              <div class="right_title">上市公司市值管理</div>
            </div>
            <div class="right_item">
              <div class="cir"></div>
              <div class="right_title">上市公司信息披露与投资者关系管理</div>
            </div>
            <div class="right_item">
              <div class="cir"></div>
              <div class="right_title">上市公司内部审计</div>
            </div>
            <div class="right_item">
              <div class="cir"></div>
              <div class="right_title">上市公司治理与规范运作</div>
            </div>
            <div class="right_btn" @click="handleApply">预约咨询</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "19";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_title span {
  display: block;
  margin: 20px 0;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.img_box {
  width: 100%;
  height: 450px;
  margin-top: 50px;
}

.img_box img {
  display: block;
  width: 100%;
  height: 100%;
}

.introduce {
  font-size: 18px;
  color: #949494;
  padding: 0 104px;
  text-align: justify;
  line-height: 27px;
}

.introduce_list {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.introduce_item {
  width: 300px;
  height: 430px;
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-top: 10px solid #055afe;
  border-radius: 7px;
  padding: 45px 25px;
  overflow: hidden;
}

.introduce_img {
  width: 56px;
  height: 56px;
  margin: 0 auto;
}

.introduce_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.introduce_title {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  margin: 25px 0;
}

.introduce_content {
  font-size: 14px;
  color: #000000;
  line-height: 22px;
  opacity: 0.8;
  margin-bottom: 44px;
}

.introduce_btn {
  width: 132px;
  height: 35px;
  border: 2px solid #055afe;
  border-radius: 17px;
  line-height: 30px;
  color: #055afe;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

.introduce_btn:hover {
  background: #055afe;
  color: #ffffff;
}

.introduce_item:nth-child(2) .introduce_content {
  margin-bottom: 21px;
}

.series_box {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.left_img {
  width: 377px;
  height: 338px;
}

.left_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.right_list {
  margin-left: 80px;
}

.right_item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.right_item:first-child {
  margin-top: 60px;
}

.right_item .cir {
  width: 6px;
  height: 6px;
  background: #055afe;
  border-radius: 50%;
  margin-right: 10px;
}

.right_title {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.right_btn {
  width: 156px;
  height: 35px;
  background: #055afe;
  border-radius: 17px;
  font-size: 18px;
  font-weight: bold;
  color: #fffefe;
  line-height: 35px;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
